











import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({ 
  components: {
    VueApexCharts
  }
})

export default class PieChart extends Vue {
    @Prop() series;
    @Prop() labels;
    @Prop() type;
    @Prop() title;

    loading = true;
    chartOptions = {
        chart: {
            width: 380,
            type: 'pie'
        },
        labels: [],
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    mounted(){
        this.loading = true;
        this.chartOptions.labels = this.labels;
        this.loading = false;
    }

}
